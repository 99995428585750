<template>
  <suspense>
  <main class="main_v8 home">
    <h2>Loading PDF...Please Wait</h2>
    <div class="pdf">
      <pdff v-for="i in numPages"
        :key="i"
        :src="src"
        :page="i">
      </pdff>
    </div>
  </main>
  </suspense>
</template>

<script>
/* eslint-disable */
import pdff from 'vue3-pdf';

export default {
  name: 'pdfview',
  components: {
    pdff,
  },
  created() {
    let position = 0;
    for(let i=0;i<this.$store.getters.getArticle.data.length;i++)
    {
      if(this.$store.getters.getArticle.data[i].id === this.$route.params.postid)
      {
        position = i;
      }
    }
    console.log("PDF path--->" + this.$store.getters.getArticle.data[position].src);
    this.src = pdff.createLoadingTask(this.$store.getters.getArticle.data[position].src);
  },
  data() {
    return {
      src: null,
      numPages: undefined,
    };
  },
  mounted() {
    this.src.promise.then((pdff) => {
      this.numPages = pdff.numPages;
    });
  },
};
</script>

<style lang="scss" scoped>
  .pdf{
    width:100%;
    overflow: auto;
  }
  .home{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
  }
  .menu_item{
    display: inline-block;
    padding-top: 40px;
    padding-left: 32px;
    background: #FFFFFF;
    box-shadow: 0px 6px 20px 1px rgba(37, 37, 37, 0.04);
    border-radius: 16px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 24px;
    text-decoration: none;
    height: 298px;
    flex: 0 0 32%;
    >svg{
      height: 64px;
      width: 64px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      margin-bottom: 90px;
    }
    >h3{
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;
      margin-bottom: 8px;
      margin-top: 0px;
      color: #252525;
    }
    >p{
      line-height: 24px;
      font-size: 14px;
      font-weight: 400;
      color: #787878;
      margin: 0;
    }
    >span{
      display: block;
      height: 32px;
      width: 32px;
      background-image: url(../../public/img/info_32.svg);
      position: absolute;
      right: 32px;
      top: 40px;
    }
  }
  .menu_item:hover{
    cursor: pointer;
  }
  .context{
    position: absolute;
    top: 40px;
    right: 35px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 6px 20px 0px rgba(37, 37, 37, 0.5);
    border-radius: 8px;
    padding: 7px 16px;
    z-index: 3;
    ul{
      padding: 0;
      margin: 0;
      li{
        list-style-type: none;
        margin: 10px 0;
        font-size: 12px;
        line-height: 25px;
        height: 24px;
        padding-left: 30px;
        position: relative;
        color: #787878;
        svg{
          background-repeat: no-repeat;
          height: 24px;
          position: absolute;
          left: 0px;
          top: 0px;
          width: 100%;
        }
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
@media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1366px)
and (min-height: 961px) and (orientation: portrait){
  .menu_item{
    height: 256px;
    flex: 0 0 48.5%;
    >svg{
      margin-bottom: 45px;
    }
  }
}
 @media screen and (max-width: 599px) and (orientation: portrait){
   .menu_item{
    height: 84px;
    flex: 0 0 100%;
    padding-top: 22px;
    padding-left: 20px;
    margin-bottom: 8px;
    position: relative;
    >svg{
      height: 40px;
      width: 40px;
      margin-bottom: 0px;
    }
    >h3{
      position: absolute;
      left: 78px;
      top: 20px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }
    >p{
      position: absolute;
      left: 78px;
      top: 48px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
    }
    >span{
      height: 24px;
      width: 24px;
      right: 20px;
      top: 20px;
      background-size: 100%;
    }
  }
 }
</style>
